<template>
    <div class="department-index">
        <navigationBar
        @buttonClickEmit0="addProject"
        :buttonList="buttonList"/>

        <!--搜索区-->
        <div>
            <el-form :inline="true" :model="filters" ref="filters">

                <el-form-item prop = "pro_name" label="项目名称:">
                    <el-input v-model="filters.pro_name" placeholder="请输入项目名称" size="mini"></el-input>
                </el-form-item>

                <el-form-item prop = "project_code" label="项目编码:">
                    <el-input v-model="filters.project_code" placeholder="请输入编码" size="mini"></el-input>
                </el-form-item>

                <el-form-item prop = "status" label="启用状态:">

                        </el-input>

                            <el-select v-model="filters.status" clearable placeholder="请选择" size="mini">
                                <el-option
                                v-for="item in this.dictionary_list"
                                :key="item.dict_key"
                                :label="item.dict_value"
                                :value="item.dict_key">
                                </el-option>    
                            </el-select>
                </el-form-item>
                
                <el-form-item prop = "status" label="组织">
                        <div class="block">
                            <el-cascader
                                size="mini"
                                :options="org_list"
                                :props="defaultProps"
                                v-model="filters.org_id"
                                @change="change2"
                                clearable>
                            </el-cascader>
                        </div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"  @click="getOrgProjectPage()" size="mini">查询</el-button>
                </el-form-item>
                <el-form-item style="margin-left: 0rem;">
                    <el-button type="info" size="mini" @click="resetForm()">重置</el-button>
                </el-form-item>
                    
                </el-form>
        </div>

        <!-- 列表区 -->
        <el-table :data="OrgProject_list" border highlight-current-row :cell-style="{padding:'10px'}" v-loading="listLoading" style="width: 100%; " >
                <el-table-column prop="idNo" label="序号"width="70"></el-table-column>
                <el-table-column prop="pro_name" label="项目名称" width="180"></el-table-column>
                <el-table-column prop="project_code" label="项目编码" ></el-table-column>
                <el-table-column prop="pro_info" label="项目描述" ></el-table-column>
                <el-table-column prop="org_name" label="组织名称" ></el-table-column>
                <el-table-column prop="status" label="启用状态" >
                    <template scope="scope">
                        <span v-if="scope.row.status == 1" style="color: #ff0000"> 停用</span>
                        <span v-if="scope.row.status == 0" style="color: #67C23A"> 启用</span>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间" width="180"></el-table-column>
                <el-table-column prop="update_time" label="更新时间" width="180"></el-table-column>
                <el-table-column label="操作" width="170">
                    <template slot-scope="scope">
                        <el-button  type="danger" size="mini" @click="delProject(scope.row.id)">删除</el-button>
                        <el-button  size="mini" type="warning" @click="addProject(scope.row)">修改</el-button>
                    </template>
                </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="Pagination" style="text-align: right;margin-top: 10px;">
            <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="pageNo"
                    :page-size="pageSize"
                    layout="total, prev, pager, next"
                    :total="count">
            </el-pagination>
        </div>

        <!-- 添加或编辑项目弹框 -->
        <div>
            <el-dialog :title=project_form_title  :visible.sync="dialogFormVisible" width="40%">
                <el-form ref="project_form" :rules="rules" label-width="15%" :model="project_form">
                    <el-form-item label="名 称" prop="pro_name">
                        <el-input type="pro_name" v-model="project_form.pro_name" placeholder="请输入项目名称" style="width: 80%;" ></el-input>
                    </el-form-item>

                    <el-form-item label="编 码" prop="project_code">
                        <el-input type="project_code"   v-model="project_form.project_code" placeholder="请输入项目编码" style="width: 80%;"></el-input>
                    </el-form-item>

                    <el-form-item label="描述" prop="pro_info">
                        <el-input type="pro_info"   v-model="project_form.pro_info" placeholder="请输入项目描述" style="width: 80%;"></el-input>
                    </el-form-item>

                    <el-form-item label="状态" prop="status">
                        <template v-for="item in this.dictionary_list">
                            <el-radio v-model="project_form.status" :label="item.dict_key">{{item.dict_value}}</el-radio>
                        </template>
                    </el-form-item>

                    <el-form-item label="组织" prop="org_id">
                        
                        <div class="block">
                            <el-cascader
                                :options="org_list"
                                :props="defaultProps"
                                v-model="project_form.org_id"
                                @change="change"
                                clearable>
                            </el-cascader>
                        </div>

                    </el-form-item>

                </el-form>

                <div slot="footer" class="dialog-footer">
                    <el-button size="mini" type="primary" @click="submit()">提 交</el-button>
                    <el-button size="mini" @click="dialogFormVisible = false">取 消</el-button>
                </div>
            </el-dialog>
        </div>
  </div>
</template>

<script>
import navigationBar from '@/components/navigationBar' 
import {getOrgProjectPage,getOrganizationInfoList,addAndEditProject,delProject} from '@/api/getUcenterData'
import {getSysDictionaryList} from '@/api/getBaseData'
export default {
    data() {
      return {
        count: 0,
        pageNo: 1,
        pageSize:10,

        //页头框中的按钮组
        buttonList:[
            {
                backgroundColor:'#52D1AF',
                icon:'/static/img/add.png',
                text:'添加项目',
            }
        ],

        // 项目列表
        OrgProject_list:[],
        // 组织列表
        org_list:[],
        // 项目状态的字典列表
        dictionary_list:{},

        // 列表的等待提示
        listLoading:false,
        // 搜索区表单
        filters:{},

        // 弹框里的form表单
        project_form:{
            id:'',
            org_id : '',
            project_code : '',
            pro_name : '',
            pro_info : '',
            status : ''
        },

        // 弹框的名称
        project_form_title:'',

        // 弹框显示标志
        dialogFormVisible:false,

        // 级联选择器的映射
        defaultProps: {
            checkStrictly : true,
            children: 'children_list',
            label: 'org_name',
            value:'id',
        },
        // 表单权限
        rules:{
            pro_name: [
                {required: true, message: '请输入名称',trigger: 'blur'},
            ],
            project_code:[
                {required: true, message: '请输入编码',trigger: 'blur'},
            ],
            status:[
                {required: true, message: '请选择状态',trigger: 'blur'},
            ],
            org_id:[
                {required: true, message: '请选择组织',trigger: 'blur'}
            ]

        },



      }
    },
    components:{
        navigationBar
    },
    created(){

    },
    mounted(){
        this.getOrgProjectPage();
        this.getOrganizationInfoList();
        this.getDictionary();
       
    },
    
    methods:{
        getOrgProjectPage(){
            let para = {
                pageNo: this.pageNo,
                pageSize:this.pageSize,
                pro_name:this.filters.pro_name,
                project_code:this.filters.project_code,
                status:this.filters.status,
                org_id:this.filters.org_id

            }
            getOrgProjectPage(para).then((result)=>{
                var res = result.data
                this.OrgProject_list = res.list;
                this.count = res.total;
                for (var i = 0; i < this.OrgProject_list.length; i++) {
                    this.OrgProject_list[i].idNo = (this.pageNo - 1) * this.pageSize + i + 1;
                }
            // this.OrgProject_list = res.data.list;
            })
        },

        // 分页的按钮触发的函数
        handleCurrentChange: function (pageNo) {
          this.pageNo = pageNo;
          this.getOrgProjectPage();
        },

        // 重置函数
        resetForm:function(){
            this.filters = {}
        },

        // 添加或编辑项目打开弹框
        addProject:function(data){

            if(data==null){
                this.project_form_title = "添加"
                
            }else{
                this.project_form_title = "编辑"
                this.project_form.id = data.id;
                this.project_form.pro_name = data.pro_name;
                this.project_form.org_id = data.org_id;
                this.project_form.project_code = data.project_code;
                this.project_form.pro_info = data.pro_info;
                this.project_form.status = data.status;
            }
            this.dialogFormVisible = true;
        },

        // 添加或编辑提交
        submit:function(){
            // this.project_form.pro_name == '' || this.project_form.project_code == '' || this.project_form.status == '' || this.project_form.org_id == ''
            if( this.project_form.pro_name == '' || this.project_form.project_code == '' || this.project_form.org_id == ''){
                this.$message({message: "请填写必填字段",type: 'error'});
            }else{
                let para = this.project_form
                addAndEditProject(para).then((result)=>{
                    if(result.resultCode==1){
                        this.$message({message: result.resultMsg,type: 'success'});
                    }else if(result.resultCode==-1001){
                        this.$message({message: result.resultMsg,type: 'error'});
                    }
            });
            this.dialogFormVisible = false;
            }
            
        },

        // 获取组织
        getOrganizationInfoList:function(){
            getOrganizationInfoList().then((result)=>{
                this.org_list = result.data;
            });
        },

        // 级联选择器选值改变时触发的函数（添加或编辑中）
        change: function (value) {
            console.log("change传来的参数,",value)
            let org_id = value[value.length - 1];
            this.project_form.org_id = org_id
            // this.filters.org_id = org_id
            console.log("选中的id，",this.project_form.org_id);
        },
        // 级联选择器选值改变时触发的函数（搜索框中）
        change2: function (value) {
            if(value!=null){
                let org_id = value[value.length - 1];
                this.filters.org_id = org_id
                console.log("搜索框中选中的id，",this.filters.org_id);
            }

        },
        
        // 获取字典
        getDictionary:function(){
            let para = {
                classifyCode:'pro_status'
            }
            getSysDictionaryList(para).then((result)=>{
                this.dictionary_list = result.data
                console.log("字典列表",this.dictionary_list);
            });
        },

        // 删除项目
        delProject:function(id){
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
              type: 'warning'
          }).then(()=>{
              let para = {
                  id:id
              }
              delProject(para).then((result)=>{
                if(result.resultCode==1){
                    this.$message({message: result.resultMsg,type: 'success'});
                }else if(result.resultCode==-1001){
                    this.$message({message: result.resultMsg,type: 'error'});
                }
              });
          });
        }

    },

    
  }
</script>
<style lang="scss">
    .department-index{
        .department-tree{
            margin-top:10px;
            .el-tree-node__content{
                height: 40px;
            }
            .el-tree-node {
                border-bottom: 1px solid #ECEEF6;
            }
            .el-tree-node:last-child {
                border-bottom: 0px solid #ECEEF6; 
            }
            .custom-tree-node {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 16px;
                padding-right: 8px;
                .tree-button{
                    float:right;
                    display:inline-block;
                    span:first-child{
                        margin-right:100px;
                    }
                }
            }
        }
        .el-dialog__header {
            background: #E9ECEE;
            padding: 1rem;
        }
        .el-dialog__headerbtn {
            top: 1.25rem;
        }
        .el-dialog__title{
            font-weight: bold;
        }

                .el-table thead {
            color: #21293B;
            font-size: 0.8rem;
        }
        .el-table th, .el-table thead {
            background-color: #E9ECEE;
            height: 3rem;
        }
        .el-table {
            font-size: 0.8rem;
        }
    }
</style>
